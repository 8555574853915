import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Globe, ExternalLink } from "react-feather"
import Merilin from "../../images/merilin_lukk.jpeg"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import WorldMap from "../../components/worldmap"
import AniLink from "gatsby-plugin-transition-link/AniLink"

export default class EstoniaInterview extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showEcosystemsLink: false,
      showNextLink: false,
      showPrevLink: false,
    }

    this.escFunction = this.escFunction.bind(this)
  }

  escFunction(event) {
    // Esc key press
    if (event.keyCode === 27) {
      this.setState({
        showEcosystemsLink: true,
      })

      setTimeout(() => {
        let linkRef = document.getElementById("esc-action")
        linkRef.click()
      }, 50)
    }

    // Left Arrow key press
    if (event.keyCode === 37) {
      this.setState({
        showPrevLink: true,
      })

      setTimeout(() => {
        let prevLinkRef = document.getElementById("prev-action")
        prevLinkRef.click()
      }, 50)
    }

    // Right Arrow key press
    if (event.keyCode === 39) {
      this.setState({
        showNextLink: true,
      })

      setTimeout(() => {
        let nextLinkRef = document.getElementById("next-action")
        nextLinkRef.click()
      }, 50)
    }
  }
  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false)
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false)
  }

  render() {
    return (
      <Layout>
        {this.state.showEcosystemsLink && (
          <AniLink
            id="esc-action"
            fade
            to="/community"
            duration={0.4}
          ></AniLink>
        )}
        {this.state.showPrevLink && (
          <AniLink
            id="prev-action"
            fade
            to="/interviews/evarvest"
            duration={0.4}
          ></AniLink>
        )}
        {this.state.showNextLink && (
          <AniLink
            id="next-action"
            fade
            to="/community"
            duration={0.4}
          ></AniLink>
        )}
        <SEO
          title="Merilin Lukk: Startup Visa Estonia Program Manager"
          seo="Merilin Lukk is the program manager for the Estonian startup visa program, among her responsibilities are making sure that the startup visa program's goals of nourishing the Estonian startup ecosystem with talent and startups"
        />

        <div id="article-top">
          <div id="contains-map">
            <div id="estonia_map_container">
              <WorldMap />
            </div>
          </div>
          <div id="left-col">
            <div id="left-col-content">
              {/* <div className="page-icon-container">
            <Mic className="page-icon" />
          </div> */}
              <h1 id="community-header">
                Merilin Lukk: Startup Estonia Program Manager
              </h1>

              <article>
                <p>
                  The startup visa in Estonia was created for Estonian startups
                  to hire talent globally and fast-track their immigration.
                  Estonian immigration policy is rather strict and there&rsquo;s
                  a quota on temporary residence permits for foreign talent,
                  which was filled by the 2nd of January this year and startups
                  need more talent to come through bypassing the quota and the
                  stringent requirements.
                </p>

                <p>
                  Case in point, Bolt, Pipedrive, Transferwise are the biggest
                  startups in Estonia and their founders are bringing talent in.
                </p>

                <p>
                  We try not to differentiate between local and international
                  founders, what we do gather is the amount of number 650:130
                  foreign founders. We don&rsquo;t exactly track how much funds
                  a given company raises, as seeing we&rsquo;re a governmental
                  entity, we primarily track taxes paid, employees, hired, and
                  other economic metrics but we do not keep metrics of each of
                  our individual companies beyond that.
                </p>

                <h3>Background</h3>

                <p>
                  The discussion about having a better way for foreign talent to
                  come work in Estonia was initiated in 2016, when our Prime
                  Minister held a roundtable with startup founders, the relative
                  scarcity of talent in Estonia is a well-known fact, and we had
                  to look beyond the borders of the EU. Roughly less than a year
                  later in 2017 the startup visa was launched. with the leaders
                  of the Estonian startup ecosystem to help for startups to
                  bring their talent, as we had an established startup system
                  and there was clearly a need for talent to fuel
                  growth&mdash;any successful startup ecosystem is international
                  in impact and accessibility.
                </p>

                <p>
                  A lot of government agencies were involved in making the
                  startup visa happen, as it was developed in conjunction with
                  the public and private sectors, the Minister of Foreign
                  Affairs, Ministry of Interior Affairs, and Ministers of
                  Economic Affairs, and it certainly seems that Estonia is
                  progressive in terms of digitizing government services. While
                  historically governments have been rather conservative and
                  slow bureaucratic beasts, our e-services help us be more
                  effective and quicker&mdash;We save 2% of our annual GDP by
                  digitizing basically all services with the exceptions of
                  marriage and divorce proceedings, and real-estate transactions
                  due to a matter of principle.
                </p>

                <p>
                  So we&rsquo;re leading the curve on that front, and lots of it
                  has to do with the size of our country, if we had 10x or 20x
                  the population rolling out digital services and getting to
                  this level of adoption would have been more challenging. Our
                  leaders in the previous decades certainly made some pivotal
                  decisions that led to this outcome.
                </p>

                <h3>How it works</h3>

                <p>
                  The goal of our program is to have 1000 startups by the end of
                  2020 both home-grown and international ones. Whether it is an
                  Estonian company looking to bring talent in or a foreign
                  founder looking to establish their business in Estonia as long
                  as meets the criteria being considered a start-up per Paul
                  Graham's definition.
                </p>

                <p>
                  Our startup committee consists of 7 organizations, in many
                  countries the startup visa and who can apply for it is decided
                  by government officials, whereas we believed that it is
                  necessary to have the community involved, so we have consult
                  investors, incubators, and entrepreneurs to determine which
                  companies get &ldquo;startup status&rdquo;. After that we
                  begin the temporary residence process.
                </p>

                <p>
                  After startup visa (1 year + 6 month extension), temporary
                  residence is 5 years (+10 year extension). Permanent residence
                  require 7 years and Estonian language exam + cultural
                  assimilation exam.
                </p>

                <p>
                  You can incorporate and run your business online, but banks
                  require you to be physically present in Estonia to open an
                  account. As an alternative, you can use a 3rd party service
                  like TransferWise for instance so it&rsquo;s a time-consuming
                  process, and most banks require a physical address due to
                  their conservative nature.
                </p>

                <p>
                  It is important to note that we have a zero corporate tax
                  rate. Startups in Estonia only pays taxes on dividends and
                  salaries.
                </p>

                <h3>E-residency and the Startup Visa</h3>

                <p>
                  They aren&rsquo;t connected in a procedural or operational
                  way, but there is definitely a lot of overlap between the
                  two&mdash;a big number of founders that come to startup in
                  Estonia have started with the e-residency
                  program&mdash;because it&rsquo;s essentially the same thing
                  with far less commitment, you get to incorporate and set up
                  operations without actually having to be in Estonia (with the
                  above exception of banking services)&mdash;as relocating to
                  Estonia is certainly more commitment so it&rsquo;s a great way
                  for entrepreneurs to start building their business prior to
                  relocating.
                </p>

                <p>
                  If the founders incorporates through e-residency they can
                  acquire a visa card and a temporary resident permit.
                </p>

                <h3>Day to Day life</h3>

                <p>
                  Our weather is on the chilly side, and we have a lot of
                  forests. People who come from big cities always comment on how
                  long they&rsquo;d have to travel to get the level of natural
                  scenery that is everpresent throughout Estonian cities. In
                  Tallin everything is super close, it&rsquo;s a small city.
                  Everyone speaks English in the startup ecosystem, and we have
                  great food!
                </p>

                <p>
                  If someone has a temporary residence permit they get access to
                  all services including healthcare and education. However in
                  the public system not all doctors speak English for example,
                  so you can opt for private healthcare as an alternative. We
                  definitely need more healthcare providers that are in English.
                  We have a couple of international schools and lots of
                  businesses are being established to serve the international
                  community in Estonia.
                </p>

                <h3>Eligibility</h3>
                <p>
                  The Startup Visa program focuses on tech-based companies with
                  high growth potential and are scalable. Their product can be a
                  hardware product as long as there is a software element. We
                  don&rsquo;t accept idea-phase companies, instant
                  no&mdash;however founders can always can re-apply. At the very
                  least prospective applications should build an MVP or a
                  prototype, and have any sort of market
                  validation&mdash;revenue, or users. Make sure it&rsquo;s
                  working on some scale, 35% of companies get in.
                </p>

                <p>
                  Our ecosystem has been successful so far. Some countries don't
                  have existing scosystems and look to seed it with startups,
                  whereas more ideally you would have an existing ecosystem and
                  seek to grow it organically. The founders existing Estonians
                  have a giving back mentality whether that is with innovating
                  in policy, venture capital, and support/mentorship for other
                  founders.
                </p>

                <h3>Bootstrapping</h3>

                <p>
                  Bootstrappers&mdash;you are not expected to raise money, but
                  most usually do. Investment helps you grow faster, at the same
                  time if you're boostrapping and do it successfully, you retain
                  full power and control. We also have relations with US/Japan
                  and Chinese investors. We have an Angel network of mostly
                  Estonian startup ecosystem alumni.
                </p>

                <p>
                  One of the problems that come up frequently is that there are
                  not enough startups to invest in, there's more money than
                  startups.{" "}
                </p>

                <p>
                  Over the long term, our goal is for Estonia to be the &quot;OS
                  for your business&quot; and since it's a rather small country,
                  you and your company are expected to scale up to larger
                  markets.
                </p>
              </article>
            </div>
          </div>
          <div id="right-col">
            <div className="contact-card">
              <div className="contact-image">
                <img src={Merilin} alt="Logo" />
              </div>
              <div className="contact-name">Merilin Lukk</div>
              <div className="contact-bio">
                Startup Visa Project Lead at Startup Estonia
              </div>
              <div className="contact-links">
                <div className="contact-link-container">
                  <OutboundLink
                    href="https://www.linkedin.com/in/merilin-lukk-32227a57/"
                    rel="noreopen noreferer"
                    className="contact-link"
                  >
                    <div className="contact-icon-container">
                      <ExternalLink className="contact-icon" />
                    </div>
                    LinkedIn
                  </OutboundLink>
                </div>
              </div>
            </div>
            <AniLink fade to="/ecosystems/estonia" duration={0.4}>
              <div className="long-container">
                <div className="item-header">
                  <div className="long-icon-container">
                    <Globe className="slack-icon" />
                  </div>
                  Estonia's Ecosystem
                  <div className="item-arrow">→</div>
                </div>
                <div className="item-description"></div>
              </div>
            </AniLink>
            <AniLink fade to="/startup-visas/estonia" duration={0.4}>
              <div className="long-container">
                <div className="item-header">
                  <div className="long-icon-container">
                    <Globe className="slack-icon" />
                  </div>
                  Get Startup Visa
                  <div className="item-arrow">→</div>
                </div>
                <div className="item-description"></div>
              </div>
            </AniLink>
          </div>
        </div>

        <div className="interview-body"></div>
      </Layout>
    )
  }
}
